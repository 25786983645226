import React, { useEffect, useState } from 'react';
import { Typography, Anchor } from 'antd';
import logo from "../../assets/new_logo.png"
import { Link } from 'react-router-dom';


const { Title, Paragraph, Text } = Typography;

export const PrivacyPolicy = () => {
    return (
        <>
            <style type="text/css">
                {`
                .content-container {
                    display: flex;
                }
                .navigation {
                    margin-right: 20px;
                    flex: 0 0 200px; /* фиксированная ширина для навигации */
                }
                .text-content {
                    flex: 1; /* оставшееся пространство для текста */
                }
                `}
            </style>
            <Link to="/" title="Политика конфиденциальности">
                <div className='logo' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={logo} alt="logo" style={{ width: '200px', height: 'auto' }} />
                </div>
            </Link>
            <div className="container mt-3 content-container">
                <div className="navigation mt-3">
                    <Anchor
                        affix={true}
                        items={[
                            {
                                key: 'part-1',
                                href: '#part-1',
                                title: 'Требования к размещению и содержанию объявлений',
                                children: [
                                    {
                                        key: 'part-1-1',
                                        href: '#part-1-1',
                                        title: 'Объявление должно быть законным',
                                    },
                                    {
                                        key: 'part-1-2',
                                        href: '#part-1-2',
                                        title: 'Информация в объявлении должна быть достоверной и полной',
                                    },
                                    {
                                        key: 'part-1-3',
                                        href: '#part-1-3',
                                        title: 'Объявление должно соответствовать категориям на HVALA',
                                    },
                                    {
                                        key: 'part-1-4',
                                        href: '#part-1-4',
                                        title: 'Предложение должно быть конкретным, существующим и актуальным',
                                    },
                                    {
                                        key: 'part-1-5',
                                        href: '#part-1-5',
                                        title: 'Запрещено размещать повторные объявления',
                                    },
                                ],
                            },
                            {
                                key: 'part-2',
                                href: '#part-2',
                                title: 'Политика обработки данных',
                                children: [
                                    {
                                        key: 'part-2-1',
                                        href: '#spart-2-1',
                                        title: 'Общие положения',
                                    },
                                    {
                                        key: 'part-2-2',
                                        href: '#part-2-2',
                                        title: 'Определения',
                                    },
                                    {
                                        key: 'part-2-3',
                                        href: '#part-2-3',
                                        title: 'Какие данные мы обрабатываем',
                                    },
                                    {
                                        key: 'part-2-4',
                                        href: '#part-2-4',
                                        title: 'Цели обработки персональных данных',
                                    },
                                    {
                                        key: 'part-2-5',
                                        href: '#part-2-5',
                                        title: 'Правовые основания обработки',
                                    },
                                    {
                                        key: 'part-2-6',
                                        href: '#part-2-6',
                                        title: 'Передача третьим лицам',
                                    },
                                    {
                                        key: 'part-2-7',
                                        href: '#part-2-7',
                                        title: 'Размещение данных на HVALA',
                                    },
                                    {
                                        key: 'part-2-8',
                                        href: '#part-2-8',
                                        title: 'Хранение персональных данных',
                                    },
                                    {
                                        key: 'part-2-9',
                                        href: '#part-2-9',
                                        title: 'Обновления настоящей политики обработки данных',
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
                <div className="text-content">
                    <Typography>
                        <Title>Правила и соглашения</Title>
                        <Paragraph id='part-1'>
                            <h4>Требования к размещению и содержанию объявлений</h4>
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-1-1'>1. Объявление должно быть законным</Text><br />
                            Не размещайте в объявлениях предложения, условия или информацию, нарушающие законодательство Черногории или права третьих лиц, либо способствующие таким нарушениям (например, требование о передаче паспорта в залог).
                            Вы вправе размещать объявления только о тех товарах, услугах, вакансиях, объектах и других предложениях (далее – «товар»), в отношении которых у вас есть все необходимые права, лицензии, регистрации, разрешения, сертификаты и т.п.
                            До размещения объявления вы обязаны убедиться в законности всех его элементов (включая фотографии, видео, ссылки на сторонние сайты, когда они допустимы, и т.п.). За любые допущенные вами нарушения законодательства или прав третьих лиц вы будете нести ответственность самостоятельно.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }}id='part-1-2'>2. Информация в объявлении должна быть достоверной и полной</Text><br />
                            Мы рассматриваем как грубое нарушение правил HVALA размещение в объявлении любой информации, которая может ввести в заблуждение относительно вашего товара или условий вашего предложения.
                            Это также означает, что в объявлении должен быть указан актуальный номер телефона, по которому с вами можно связаться.

                            Если ваше предложение должно в силу закона содержать какие-либо сведения, вы обязаны их указать.

                            Вы самостоятельно несете всю ответственность за распространение объявления, в котором отсутствуют обязательные сведения или указана недостоверная информация.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-1-3'>3. Объявление должно соответствовать категориям на HVALA</Text><br />
                            Размещайте объявления только о тех товарах, для которых на HVALA существует тематическая категория. Не размещайте объявления о принятии в дар, заказе услуг, сборе материальной помощи, знакомствах, находке, потере, покупке или поиске. Исключения: объявление о покупке недвижимости вы можете разместить только в категории «Недвижимость», объявление о поиске работы или сотрудника – только в категории «Работа».
                            Объявления могут быть размещены в рамках одной категории с использованием только одного профиля (в том числе, если вы действуете в рамках одной компании или совместного бизнеса, семьи или в интересах ваших знакомых).
                            Запрещается размещать несколько товаров в одном объявлении, за исключением случаев, если они составляют комплект или соответствуют одной категории. Если в объявлении размещено несколько товаров, соответствующих одной категории, для каждого товара должна быть указана цена.
                            Исключения: – в категориях «Недвижимость», «Транспорт», «Вакансии» и «Телефоны» вы обязаны размещать отдельное объявление на каждое предложение.
                            Мы вправе устанавливать ограничения на количество бесплатно размещаемых объявлений для определенных категорий и регионов (лимит) для того, чтобы пользователи, особенно из крупных регионов, могли видеть предложения от разных продавцов. При достижении лимита в течение 30 и/или 365 календарных дней до момента размещения объявления последующая подача нового или активация ранее созданного объявления в соответствующих категориях и регионах возможна за плату, в соответствии с Условиями платного размещения объявлений.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-1-4'>4. Предложение должно быть конкретным, существующим и актуальным</Text><br />
                            Размещайте объявления о конкретном существующем имеющем потребительскую ценность товаре. Например, на HVALA нельзя продать дырку от пончика или сдать в аренду квартиру на Марсе.
                            Если вы размещаете объявление о продаже товара – товар должен быть у вас в наличии, если вы размещаете объявление об услуге – вы должны быть готовы ее оказать, если вы размещаете объявление об аренде недвижимости – вы должны быть готовы передать ее арендатору и т.д. Мы допускаем размещение товаров по предзаказу, в том числе из-за границы, и товаров, которые скоро выйдут в продажу. Размещение товаров, изготавливаемых под заказ, возможно в подкатегориях «Мебель и интерьер», «Ремонт и строительство», «Посуда и товары для кухни», «Запчасти». Если вы предлагаете товар под заказ, при размещении в параметре «Доступность» выбирайте значение «Под заказ», если такая возможность доступна в интерфейсе HVALA.
                            В некоторых категориях может быть доступно указание количества единиц товара в наличии. Если такая возможность доступна в интерфейсе HVALA, вы можете указать, что в наличии есть несколько единиц товара, и отметить точное количество имеющегося товара. Если вы не указали количество, считается, что товар в наличии один.
                            Поддерживайте актуальную информацию о количестве товара в наличии в профиле HVALA. Объявление может быть снято с публикации, если иное не предусмотрено правилами или в интерфейсе HVALA. Снятие объявления с публикации по причине окончания товара в наличии равнозначно его снятию с публикации самим пользователем. Если товар еще есть в наличии, обновите информацию о его количестве в профиле HVALA.
                            Если вы продали товар или ваше предложение перестало быть актуальным по другим причинам, снимите объявление с публикации как можно скорее. Не изменяйте содержание ранее опубликованного объявления. В том числе не загружайте фото другого товара или объекта недвижимости. Для нового предложения создайте новое объявление.
                            При наличии технической возможности и только при включенной в профиле функции автоматической активации, HVALA автоматически активирует объявления после того, как истек очередной максимальный срок их размещения. Если у вас нет потребности в автоматической активации объявлений, отключите её в профиле или обратитесь к специалистам службы поддержки.
                            Не размещайте объявления без цели заключить сделку в отношении именно того товара, который указан в объявлении (фиктивные объявления). Запрещено размещать объявления для целей лидогенерации — получения контактных данных потенциальных покупателей. Постоянно проводятся усиленные проверки актуальности объявлений для выявления фиктивных.
                            Помните, что размещение объявления может повлечь для вас обязательство перед потенциальным покупателем заключить и исполнить сделку в соответствии с гражданским законодательством.
                            Не размещайте в объявлениях информацию общего рекламного характера без конкретного предложения. Размещение объявлений на HVALA производится в целях информирования пользователей о возможности и условиях совершения сделок. Сами объявления, а также содержащаяся в них информация, не направлены на формирование и поддержание интереса к конкретному лицу, его товару или услуге.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-1-5'>5. Запрещено размещать повторные объявления</Text><br />
                            Вы можете разместить только одно действующее объявление для одного товара. Мы рассматриваем размещение повторных объявлений (дублей) как грубое нарушение правил HVALA.
                            Это означает, что вы не можете размещать повторные объявления (дубли) о предложении одного и того же товара в разных городах или районах.
                            Размещением повторного объявления (дубля) также будет считаться, если:
                            вы снимете объявление с публикации или удалите его (в том числе до истечения срока его размещения), а затем разместите новое объявление с тем же товаром, в том числе в другом регионе или в другой категории;
                            вы отредактируете опубликованное объявление, заменив предлагаемый товар на другой, а затем разместите новое объявление, в котором будет предлагаться товар из первоначальной версии старого объявления.
                            При совпадении параметров объявления с аналогичными параметрами другого ранее размещенного объявления, более позднее объявление считается повторным и может быть заблокировано.
                        </Paragraph>

                        <Paragraph>
                            <h4 id='part-2'>Политика обработки данных</h4>
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-1'>1. Общие положения</Text><br />
                            HVALA («мы») обрабатывает персональные данные зарегистрированных и незарегистрированных пользователей – всех, кто обращается к нашему сайту и мобильным приложениям, а также связывается c HVALA по номерам телефона, указанным на сайте («пользователи», или «вы»).
                            Предоставляя нам персональные данные, вы соглашаетесь на их обработку в соответствии с этой политикой.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-2'>2. Определения</Text><br />
                            Персональные данные – любая информация, относящаяся к прямо или косвенно определённому, или определяемому физическому лицу (субъекту персональных данных).
                            Обработка персональных данных – осуществление действий или совокупности действий в отношении персональных данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение, обновление и изменение, извлечение, использование, предоставление, доступ, блокирование, удаление и уничтожение – как с использованием, так и без использования средств автоматизированной обработки данных.
                            Мы можем обрабатывать ваши данные перечисленными способами в целях, закрепленных в разделе 4 настоящей Политики.
                            Оператор персональных данных – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                            Мы являемся оператором по отношению к персональным данным, которые мы можем получить в связи с использованием вами HVALA (включая сайт и мобильные приложения).
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-3'>3. Какие данные мы обрабатываем</Text><br />
                            Вы предоставляете нам персональные данные, когда:
                            <ul>
                                <li>регистрируетесь на HVALA;</li>
                                <li>используете HVALA;</li>
                                <li>оплачиваете услуги HVALA;</li>
                                <li>подписываетесь на рассылки;</li>
                                <li>участвуете в мероприятиях, исследованиях и опросах;</li>
                                <li>пишете или звоните нам (например, когда обращаетесь в поддержку);</li>
                                <li>общаетесь с другими пользователями;</li>
                                <li>пользуетесь правами или выполняете обязанности на основании наших условий и правил.</li>
                            </ul>
                            Мы можем получить ваши персональные данные от наших партнёров в связи с исполнением договора, по которому вы являетесь стороной или выгодоприобретателем.
                            Ваше устройство автоматически передает технические данные: информацию, сохраненную в файлах куки (cookies), информацию о браузере и его настройках, дате и времени доступа к HVALA, адресах запрашиваемых страниц, действиях на сайте или в приложении, технических характеристиках устройства, IP-адресе и т.п.
                            Мы обрабатываем только общие категории персональных данных. Мы не обрабатываем биометрические персональные данные и специальные категории персональных данных.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-4'>4. Цели обработки персональных данных</Text><br />
                            <ul>
                                <li>Предоставление вам возможности пользоваться HVALA: создавать профиль, размещать объявления и т.п., а также, с вашего согласия – предоставлять вам возможность пользоваться услугами наших партнёров;</li>
                                <li>Обеспечение стабильной работы и безопасности HVALA, улучшение пользовательского опыта, качества услуг и маркетинговых мероприятий;</li>
                                <li>Предупреждение и пресечение нарушения законодательства, Условий использования HVALA и других наших правил, и условий, в том числе защита пользователей от мошеннических и иных недобросовестных действий;</li>
                                <li>Выполнение обязанностей, предусмотренных законодательством (например, для бухгалтерского учёта, налоговой отчётности, ответов на запросы государственных органов);</li>
                                <li>Предоставление ответов на ваши обращения;</li>
                                <li>Направление маркетинговых сообщений об HVALA и наших партнерах;</li>
                                <li>Организация вашего участия в мероприятиях, исследованиях и опросах HVALA и наших партнеров.</li>
                            </ul>
                            Если вы обращаетесь к нам как представитель государственного органа или СМИ, мы обрабатываем данные, указанные в обращении (фамилия, имя, отчество, адрес электронной почты, номер телефона, должность, место работы) в целях выполнения обязанностей, предусмотренных законодательством, и ответа на ваше обращение.
                            Если вы обращаетесь к нам как правообладатель, мы обрабатываем данные, указанные в обращении (фамилия, имя, отчество, адрес электронной почты, номер телефона, паспортные данные, сведения, подтверждающие наличие исключительного права и ваши полномочия) в целях выполнения обязанностей, предусмотренных законодательством, и ответа на ваше обращение.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-5'>5. Правовые основания обработки</Text><br />
                            Мы обрабатываем ваши персональные данные:
                            <ul>
                                <li>с вашего согласия. Вы даёте согласие на обработку ваших персональных данных при регистрации и каждом случае входа на HVALA, а также в процессе использования HVALA. Например, когда вы выполняете инструкции в интерфейсах HVALA, нажимаете на специальные кнопки, пишите сообщения или совершаете иные действия. Вы даете согласие на 1 год с даты удаления вашего профиля на HVALA либо, если вы являетесь незарегистрированным пользователем, - с даты достижения цели обработки персональных данных;</li>
                                <li>для заключения, исполнения, изменения или прекращения договора – Условий использования HVALA и других наших условий, и правил, а также ваших договоров с нашими партнёрами;</li>
                                <li>для осуществления прав и законных интересов HVALA или третьих лиц, если при этом не нарушаются ваши права и свободы;</li>
                                <li>для выполнения обязанностей, возложенных на нас законодательством.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-6'>6. Передача третьим лицам</Text><br />
                            Мы можем передавать персональные данные или поручать их обработку третьим лицам, если это необходимо для предоставления вам сервисов HVALA либо, в случае предоставления иных сервисов – с вашего отдельного согласия.
                            Мы можем передать ваши персональные данные нашим партнёрам, которые участвуют в предоставлении вам тех или иных сервисов, а также государственным органам, если мы обязаны предоставить им ваши персональные данные в соответствии с законом.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-7'>7. Размещение данных на HVALA</Text><br />
                            Когда вы публикуете на HVALA объявления, отзывы или информацию в своём профиле, персональные данные в составе такой информации становятся доступными неопределённому кругу лиц. Вы раскрываете такие данные самостоятельно без предоставления нам как оператору персональных данных отдельного согласия. HVALA не распространяет ваши персональные данные.
                            Цель, с которой пользователи размещают данные на HVALA – установить контакт с потенциальным покупателем (клиентом, работодателем), который заинтересован в заключении сделки по объявлению. Пользователи не вправе обрабатывать данные других пользователей для любых иных целей. Это означает, что:
                            <ul>
                                <li>вы не можете звонить или отправлять сообщения пользователям, чтобы предлагать свои товары или услуги;</li>
                                <li>вы не можете копировать данные пользователей, чтобы размещать их на других сервисах;</li>
                                <li>вы не можете использовать информацию пользователей HVALA в целях скоринга.</li>
                            </ul>
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-8'>8. Хранение персональных данных</Text><br />
                            Мы осуществляем запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение ваших персональных данных с использованием баз данных.
                            Мы храним ваши данные в соответствии со сроками обработки данных, необходимыми для достижения целей обработки, указанных в разделе 4 настоящей Политики.
                        </Paragraph>

                        <Paragraph style={{ fontSize: '16px' }}>
                            <Text strong style={{ fontSize: '16px' }} id='part-2-9'>9. Обновления настоящей политики обработки данных</Text><br />
                            Мы можем время от времени обновлять настоящую Политику обработки данных.
                            О любых существенных изменениях настоящей Политики мы уведомим Вас, разместив уведомление в нашем приложении (на нашем веб-сайте) или отправив письмо на Ваш адрес электронной почты.
                        </Paragraph>
                    </ Typography>
                </div>
            </div >
        </>
    )
}