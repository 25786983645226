import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/locales/en.json";
import ru from "./assets/locales/ru.json";
import sr from "./assets/locales/me.json";

import { UserRoleProvider } from './context/UserRoleContext';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      ru: { translation: ru },
      sr: { translation: sr }
    },
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserRoleProvider>
    <App />
  </UserRoleProvider>
);
reportWebVitals();
